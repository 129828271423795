* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

body {
  background: #f5f5f5;
}

body, input, button {
  font-family: 'Montserrat', 'Arial', Helvetica, sans-serif;
}

.App {
  height: 100%;
}